import React from 'react'
import {Icon, translate as _} from '@morawadigital/skynet-framework'
import ItemsPage from "./../ItemsPage";
import Athlete from "../../items/Athlete";
import {Link} from "react-router-dom";
import {Form} from "react-bootstrap";
import {findTextInObject} from "../../../util";

function Events(props) {

    const renderSubheaderBlock = () => {
        return (
            <Link to={_('/athletes')} className='btn btn-primary'><Icon icon='file-excel me-2'/> {_('Export')}</Link>
        )
    }

    const checkItem = (item) => {
        return !(props.textFilter && !findTextInObject(item, props.textFilter, ['Firstname','Lastname','Birthday']));
    }

    const renderFilters = () => {
        return (
            <Form.Control
                className="mb-4"
                onChange={e => props.onUiValueChange('textFilterEvents', e.target.value)}
                placeholder={_('Athlete filtern...')}
                value={props.textFilter}
            />
        )
    }

    return (
        <ItemsPage
            dataMethod='POST'
            ItemComponent={Athlete}
            pageIcon='user-friends'
            pageTitle={_('Athletes')}
            getDataUrl='api/Athlete/Get'
            renderSubheaderBlock={renderSubheaderBlock}
            renderFilters={renderFilters}
            checkItem={checkItem}
            {...props}
        />
    )
}

export default Events