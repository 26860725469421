import {useEffect, useState} from "react";
import {ajxs} from "@morawadigital/skynet-framework";
import useCreateExercise from "./use-create-exercise";
import {getFields} from "../../components/pages/Exercises/fields";
import useApiOptions from "../use-api-options";

const useExerciseResults = (props) => {
    const {
        id,
        getId
    } = props

    const [valueOptions, setValueOptions] = useState({})
    const fieldDefinition = getFields()

    const [generateApiOptions] = useApiOptions({setValueOptions})

    const optionsArray = [
        {
            key: 'categories',
            url: 'api/Category/Get',
            method: 'POST'
        },
    ]

    const items = generateApiOptions(optionsArray);

    const [exercise, setExercise, create, resetCompetition] = useCreateExercise()

    const onLoad = () => {
        items.push(
            {
                options: {method: 'POST'},
                success: (e) => setExercise(create(...e.filter(item => item.Id === parseInt(id)))),
                url: 'api/Exercise/Get',
                timeout: 400,
                toggleLoading: true,
            },
        )

        ajxs({toggleLoading: true}, items)
    }


    useEffect(() => {
        onLoad()

        return () => {
            resetCompetition()
        }
    }, [getId])

    return [fieldDefinition, valueOptions, exercise, setExercise]
}

export default useExerciseResults



