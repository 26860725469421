import React, {useState, useEffect} from 'react';
import {ajx, formatDate, Icon, translate as _} from '@morawadigital/skynet-framework';
import {Button, Form, Modal, Table} from 'react-bootstrap';
import cn from "classnames";


const EventAthletes = (props) => {
    const {eventId} = props
    const [athletes, setAthletes] = useState({})
    const [saving, setSaving] = useState(false)
    const [validated, setValidated] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);


    const load = () => {
        ajx({
            options: {method: 'POST'},
            success: (e) => setAthletes(e),
            url: 'api/Athlete/Get',
        })
    }

    useEffect(() => {
        load()
    }, [])

    const save = e => {
        e.preventDefault()

        if (!e.currentTarget.checkValidity()) {
            setValidated(true)
            return;
        }

        setSaving(true)
        setValidated(true)


        const data = {
            eventId: eventId,
            athIds: {__arr: true, values: selectedRows},
            sportId: 5,
            allExercises: true,
        }

        const success = () => {
            props.onHide()
        }

        ajx({
            complete: () => setSaving(false),
            single: true,
            url: 'api/Event/MapAthletesToEvent',
            data,
            success,
        })
    }


    const renderTitle = () => {
        if (props.isNew) {
            return _('New Athlete')
        } else {
            return <Icon icon='spinner' spin/>;
        }
    }


    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    }

    const filteredAthletes = athletes && athletes.length && athletes?.filter((athlete) => {
        const {Firstname, Lastname, Birthday} = athlete;
        const lowerSearchQuery = searchQuery.toLowerCase();

        return (
            Firstname.toLowerCase().includes(lowerSearchQuery) ||
            Lastname.toLowerCase().includes(lowerSearchQuery) ||
            formatDate(Birthday).toLowerCase().includes(lowerSearchQuery)
        )
    })


    const handleRowClick = (id) => {
        const selectedIndex = selectedRows.indexOf(id)
        let newSelectedRows = [...selectedRows]

        if (selectedIndex === -1) {
            newSelectedRows = [...newSelectedRows, id];
        } else {
            newSelectedRows.splice(selectedIndex, 1)
        }

        setSelectedRows(newSelectedRows);
    }

    return (
        <Modal show={props.onShow} onHide={props.onHide} backdrop='static' size='lg'>
            <Form onSubmit={save} noValidate validated={validated}>
                <fieldset disabled={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>{renderTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            className="mb-4"
                            onChange={handleSearchQueryChange}
                            placeholder={_('Athlete filtern...')}
                            value={searchQuery}
                        />
                        <div style={{height: '60rem', overflow: 'auto', paddingRight: 10}}>

                            <Table hover responsive>
                                <thead>
                                <tr>
                                    <th>{_('Name')}</th>
                                    <th>{_('Position')}</th>
                                    <th>{_('Birthdate')}</th>
                                    <th>{_('Gender')}</th>
                                    <th>{_('Club')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredAthletes && filteredAthletes.length ? (
                                    filteredAthletes.map((athlete) => (
                                        <tr key={athlete.Id}
                                            onClick={() => handleRowClick(athlete.Id)}
                                            className={cn('cursor-pointer', {
                                                'bs-gray-200': selectedRows.includes(athlete.Id),
                                            })}
                                        >
                                            <td>
                                                <div className="d-flex">
                                                    <Form.Check
                                                        aria-label={`Select athlete ${athlete.Id}`}
                                                        checked={selectedRows.includes(athlete.Id)}
                                                        onChange={() => handleRowClick(athlete.Id)}
                                                    />
                                                    <span className="ms-2">{athlete.Firstname} {athlete.Lastname}</span>
                                                </div>
                                            </td>
                                            <td>{athlete.Position?.Name || '-'}</td>
                                            <td>{athlete.Birthday ? formatDate(athlete.Birthday) : '-'}</td>
                                            <td>Vienna Vikings</td>
                                            <td>Vienna Vikings</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5}>
                                            <div className='my-3 text-center'>{_('Keine Einträge gefunden.')}</div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={props.onHide}>
                            {_('Abbrechen')}
                        </Button>
                        <Button type='submit'>{_('Speichern')}</Button>
                    </Modal.Footer>
                </fieldset>
            </Form>
        </Modal>
    )
}

export default EventAthletes;
