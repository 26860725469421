import {connect} from 'react-redux'
import {setUiValue} from "../../../store/actions";
import Athletes from "../../../components/pages/Athletes";

const mapStateToProps = state => ({
    loading: state.app.loading,
    token: state.auth.token,
    textFilter: state.ui.textFilterEvents || '',
})

const mapDispatchToProps = dispatch => ({
    onUiValueChange: (key, value) => dispatch(setUiValue(key, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Athletes)
