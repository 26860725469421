import React, {useCallback, useEffect, useState} from "react";
import ExerciseItem from "../items/Exercise";
import {Button} from "react-bootstrap";
import {ajx, Icon, translate as _} from "@morawadigital/skynet-framework";
import ExerciseModal from "../modals/EventExercises";

const EventExercises = (props) => {
    const {eventId} = props

    const [exercises, setExercises] = useState(null)
    const [exerciseEditing, setExerciseEditing] = useState(null)
    const [show, setShow] = useState(false)

    const createExercise = (exercise) => {
        const createdExercise = {
            Id: 0,
            Name: '',
            new: false,
            ...(exercise || {}),
        };

        return createdExercise;
    }

    const load = useCallback(() => {
        ajx({
            data: {eventId: eventId},
            options: {method: 'POST'},
            success: e => setExercises(e),
            url: 'api/Event/GetEventExercises'
        })
    }, [])

    useEffect(() => {
        load()
    }, [show])

    const openExercise = (e) => {
        setExerciseEditing(createExercise(e))
        setShow(true)
    }

    const deleteExercise = (exercise) => {
        ajx({
            data: {id: exercise.Id},
            url: 'api/Exercise/Delete',
            success: () => {
                props.onDelete(exercise)
            },
        })
    }

    return (
        <>
            <Button onClick={() => openExercise({new: true})}><Icon icon='plus'/> {_('Neu')}</Button>
            <div className='mt-3'>
                {exercises && exercises.length ?
                    exercises.map((exercise, index) => (
                        <ExerciseItem
                            key={index}
                            item={exercise}
                            onDelete={deleteExercise}
                            onEdit={(e) => openExercise(e)}
                        />
                    )) :
                    <div className='my-3 text-center'>{_('Keine Einträge gefunden.')}</div>
                }

                {show &&
                    <ExerciseModal
                        load={load}
                        onShow={show}
                        onHide={() => {
                            setShow(false)
                            setExerciseEditing(null)
                        }}
                        eventId={eventId}
                        isNew={exerciseEditing?.new}
                    />
                }
            </div>
        </>
    )
}

export default EventExercises;
