import {useEffect, useState} from "react";
import { ajxs} from "@morawadigital/skynet-framework";
import useCreateAthlete from "./use-create-athlete";
import {getFields} from "../../components/pages/Athletes/fields";
import useApiOptions from "../use-api-options";
import {getGenders} from "../../util";

const useAthleteResults = (props) => {
    const {
        id,
        getId
    } = props

    const [valueOptions, setValueOptions] = useState({genders: getGenders()})
    const fieldDefinition = getFields()

    const [generateApiOptions] = useApiOptions({setValueOptions})

    const optionsArray = [
        {
            key: 'positions',
            url: 'api/Position/Get',
            method: 'POST'
        },
    ]

    const items = generateApiOptions(optionsArray);

    const [athlete, setAthlete, create, resetCompetition] = useCreateAthlete()

    const onLoad = () => {

        items.push(
            {
                options: {method: 'POST'},
                success: (e) => setAthlete(create(...e.filter(item => item.Id === parseInt(id)))),
                url: 'api/Athlete/Get',
                timeout: 400,
                toggleLoading: true,
            },
        )

        ajxs({toggleLoading: true}, items)
    }


    useEffect(() => {
        onLoad()

        return () => {
            resetCompetition()
        }
    }, [getId])

    return [fieldDefinition, valueOptions, athlete, setAthlete]
}

export default useAthleteResults



