import React, {useState, useEffect} from 'react';
import {Icon, ajx, translate as _} from '@morawadigital/skynet-framework';

function ItemsPage(props) {
    const {
        dataMethod = 'GET',
        ItemComponent,
        pageIcon,
        pageTitle,
        loading,
        checkItem = e => {
            return true
        },
        convertItems = null,
        getDataParams = () => {
            return {}
        },
        getDataUrl = '',
        getItemProps = () => {
            return {}
        },

        loadFilters = function () {
        },
        renderFilters = function () {
        },
        renderFooter = function () {
        },
        renderSubheaderBlock = function () {
        }

    } = props


    const [items, setItems] = useState(null);

    useEffect(() => {
        loadFilters();
        loadItems();

    }, []);

    const loadItems = () => {
        ajx({
            data: getDataParams(),
            options: {method: dataMethod},
            success: (e) => setItems(e),
            timeout: 400,
            toggleLoading: true,
            url: getDataUrl,
        });
    }

    const filteredItems = items ? items.filter((e) => checkItem(e)) : null;

    return (
        <>
            <div className="subheader">
                <h1 className="subheader-title">
                    <Icon icon={pageIcon} className="subheader-icon"/> {pageTitle}
                </h1>
                <div className="subheader-block">{renderSubheaderBlock()}</div>
            </div>
            {renderFilters()}
            {loading ? (
                Array.from({length: 3}).map((e, i) => <ItemComponent key={i} index={i}/>)
            ) : filteredItems && !filteredItems.length ? (
                <div className="my-3 text-center">{_('Keine Einträge gefunden.')}</div>
            ) : filteredItems ? (
                <>
                    <div className="text-center text-muted mt-2 mb-3">
                        {filteredItems.length + ' ' + (filteredItems.length === 1 ? _('Eintrag') : _('Einträge'))}
                    </div>
                    {filteredItems.map((item, i) => (
                        <ItemComponent key={i} item={item} {...getItemProps()} />
                    ))}
                </>
            ) : (
                ''
            )}
            {renderFooter()}
        </>
    );
}

export default ItemsPage;
