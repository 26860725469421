import {actionTypes} from '@morawadigital/skynet-framework'
import {SET_UI_VALUE} from './constants'

const initialStateUi = {
    context: null,
    textFilterEvents: '',
}

export const ui = (state = initialStateUi, action) => {
    switch (action.type) {
        case actionTypes.LOGOUT:
        case actionTypes.RESET_APP: {
            return initialStateUi
        }
        case SET_UI_VALUE: {
            return {...state, [action.payload.key]: action.payload.value}
        }
        default:
            return state
    }

}