import React from 'react'
import {Icon, translate as _} from '@morawadigital/skynet-framework'
import ItemsPage from "./../ItemsPage";
import Event from "../../items/Event";
import {Link} from "react-router-dom";
import {Form} from "react-bootstrap";
import {findTextInObject} from "../../../util";

function Events(props) {

    const renderSubheaderBlock = () => {
        return (
            <Link to={_('/event/neu')} className='btn btn-primary'><Icon icon='plus'/> {_('Neu')}</Link>
        )
    }

    const checkItem = (item) => {
        return !(props.textFilter && !findTextInObject(item, props.textFilter, ['Name','StartDate']));
    }

    const renderFilters = () => {
        return (
            <Form.Control
                className="mb-4"
                onChange={e => props.onUiValueChange('textFilterEvents', e.target.value)}
                placeholder={_('Event filtern...')}
                value={props.textFilter}
            />
        )
    }

    return (
        <ItemsPage
            dataMethod='POST'
            ItemComponent={Event}
            pageIcon='calendar-days'
            pageTitle={_('Events')}
            getDataUrl='api/Event/Get'
            renderSubheaderBlock={renderSubheaderBlock}
            renderFilters={renderFilters}
            checkItem={checkItem}
            {...props}
        />
    )
}

export default Events