import React from 'react'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/lib/integration/react'
import storage from 'redux-persist/lib/storage'
import {combineReducers, createStore} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import {createRoot} from 'react-dom/client'
import {reducers} from '@morawadigital/skynet-framework'
import App from './js/containers/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './css/index.scss'
import { ui } from './js/store/reducers'

const combinedReducers = combineReducers({
    app: reducers.app,
    auth: reducers.auth,
    groups: reducers.groups,
    msg: reducers.msg,
    reporting: reducers.reporting,
    ui
})
const root = createRoot(document.getElementById('app-root'))
const persistConfig = {key: 'afboe-metrics-root', storage, blacklist: []}
const persistedReducer = persistReducer(persistConfig, combinedReducers)
const store = createStore(persistedReducer)
const persistor = persistStore(store)

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>
)

serviceWorkerRegistration.register({
    onUpdate: registration => {
        if (registration && registration.waiting) {
            window.appUpdateAvailable = true
        }
    }
})