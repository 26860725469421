import React, {useState, useEffect} from 'react';
import {ajx, ajxs, Icon, translate as _} from '@morawadigital/skynet-framework';
import {Button, Form, Modal,} from 'react-bootstrap';
import {createOptions, getSelectValue} from '../../util';
import FieldSet from "../elements/FieldSet";
import {getFieldsExercise} from "../pages/Events/fields";
import useUnsavedChanges from "../../hooks/use-unsaved-changes";

const EventExercises = (props) => {
    const {eventId} = props
    const [exercise, setExercise] = useState({})
    const [hasUnsavedChanges, setHasUnsavedChanges] = useUnsavedChanges();
    const fieldDefinition = getFieldsExercise()
    const [saving, setSaving] = useState(false)
    const [validated, setValidated] = useState(false)
    const [valueOptions, setValueOptions] = useState({})
    const [loading, setLoading] = useState(true)

    const load = () => {
        const items = [
            {
                options: {method: 'POST'},
                success: (e) => setValueOptions({
                    ...valueOptions,
                    exercises: createOptions(e),
                }),
                url: 'api/Exercise/Get',
            },
        ];

        ajxs({}, items)
    }

    useEffect(() => {
        load()
    }, [])

    const save = e => {
        e.preventDefault()

        if (!e.currentTarget.checkValidity()) {
            setValidated(true)
            return;
        }

        setSaving(true)
        setValidated(true)


        const data = {
            eventId: eventId,
            exerciseId: getSelectValue(exercise.Exercises),
            multiplier: exercise.Multiplier,
        }

        const success = () => {
            props.onHide()
        }

        ajx({
            complete: () => setSaving(false),
            single: true,
            url: 'api/Event/MapExercisesToEvent',
            data,
            success,
        })
    }


    const renderTitle = () => {
        if (props.isNew) {
            return _('New Exercise')
        } else {
            return <Icon icon='spinner' spin/>;
        }
    }


    const onChangeFieldHandler = (e, f) => {
        setExercise((prevComp) => ({
            ...prevComp,
            [e]: f,
        }))
        setHasUnsavedChanges(f !== null)
    }

    const formDisabled = !loading || saving

    return (
        <Modal show={props.onShow} onHide={props.onHide} backdrop='static' size='lg'>
            <Form onSubmit={save} noValidate validated={validated}>
                <fieldset disabled={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>{renderTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FieldSet
                            disabled={formDisabled}
                            fieldDefinition={fieldDefinition}
                            onChange={onChangeFieldHandler}
                            valueOptions={valueOptions}
                            values={exercise}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={props.onHide}>
                            {_('Abbrechen')}
                        </Button>
                        <Button type='submit'>{_('Speichern')}</Button>
                    </Modal.Footer>
                </fieldset>
            </Form>
        </Modal>
    )
}

export default EventExercises;
