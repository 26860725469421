import React, {useCallback, useEffect, useState} from "react";
import AthleteItem from "../../items/Athlete";
import {ajx, translate as _} from "@morawadigital/skynet-framework";
import AthleteModal from "../../modals/EventAthletes";

const ExerciseResult = (props) => {
    const {eventId} = props
    
    const [athletes, setAthletes] = useState(null)
    const [exerciseAthlete, setAthleteEditing] = useState(null)
    const [show, setShow] = useState(false)

    const createAthlete = (exercise) => {
        const createdAthlete = {
            Id: 0,
            Name: '',
            new: false,
            ...(exercise || {}),
        };

        return createdAthlete;
    }

    const load = useCallback(() => {
        ajx({
            data: {eventId: eventId},
            options: {method: 'POST'},
            success: e => setAthletes(e),
            url: 'api/Event/GetEventAthletes'
        })
    }, [])

    useEffect(() => {
        load()
    }, [show])

    const openAthlete = (e) => {
        setAthleteEditing(createAthlete(e))
        setShow(true)
    }

    const deleteAthlete = (exercise) => {
        ajx({
            data: {id: exercise.Id},
            url: 'api/Athlete/Delete',
            success: () => {
                props.onDelete(exercise)
            },
        })
    }

    return (
        <>
            {/*<Button onClick={() => openAthlete({new: true})}><Icon icon='plus'/> {_('Neu')}</Button>*/}
            <div className='mt-3'>
                {athletes && athletes.length ?
                    athletes.map((athlete, index) => (
                        <AthleteItem
                            key={index}
                            item={athlete}
                            onDelete={deleteAthlete}
                            onEdit={(e) => openAthlete(e)}
                        />
                    )) :
                    <div className='my-3 text-center'>{_('Keine Einträge gefunden.')}</div>
                }

                {show &&
                    <AthleteModal
                        load={load}
                        onShow={show}
                        onHide={() => {
                            setShow(false)
                            setAthleteEditing(null)
                        }}
                        eventId={eventId}
                        isNew={exerciseAthlete?.new}
                    />
                }
            </div>
        </>
    )
}

export default ExerciseResult;
