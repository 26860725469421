import {useCallback, useState} from "react";
import {getGenders} from "../../util";

const useCreateAthlete = () => {
    const create = (athlete) => {

        const created = {
            Id: 0,
            Firstname: null,
            Lastname: null,
            Position: null,
            SecondPosition: null,
            Birthday: null,
            Gender: null,
            ...(athlete || {}),
        }

        if (athlete) {
            created.Gender = athlete.Gender !== null ?
                getGenders().find(e => e.value === athlete.Gender)
                : null

            created.Position = athlete.Position ? {
                label: athlete.Position.Name,
                value: athlete.Position.Id,
            } : null;
        }

        return created;
    }

    const [athlete, setAthlete] = useState(create())


    const resetAthlete = useCallback(() => {
        setAthlete(create())
    }, [])

    return [athlete, setAthlete, create, resetAthlete]
}

export default useCreateAthlete



