import React from 'react'
import { ErrorBoundary, Icon, Login, MenuItem, Skynet, createDate, reloadPage } from '@morawadigital/skynet-framework'
import { Route } from 'react-router-dom'
import packageJson from '../../../package.json'
import Dashboard from './pages/Dashboard'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import Import from './pages/Import'
import Events from "../containers/pages/Events";
import Exercises from "../containers/pages/Exercises";
import Athletes from "../containers/pages/Athletes";
import EventNewOrEdit from "../containers/pages/Events/NewOrEdit";
import ExerciseNewOrEdit from "../containers/pages/Exercises/NewOrEdit";
import AthleteNewOrEdit from "../containers/pages/Athletes/NewOrEdit";

class App extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            appUpdateAvailable: false,
            updatingApp:        false,

        }

    }

    componentDidMount() {

        setTimeout( () => {

            if ( window.appUpdateAvailable ) {

                this.setState( { appUpdateAvailable: true } )

                setTimeout( () => this.setState( { appUpdateAvailable: false } ), 1000 * 60 )

            }

        }, 1000 * 5 )

    }

    hideAppUpdateNotification() {

        this.setState( { appUpdateAvailable: false } )

    }

    updateApp() {

        this.setState( { updatingApp: true }, () => {

            caches
                .keys()
                .then( keys => keys.forEach( c => caches.delete( c ) ) )
                .then( () => setTimeout( reloadPage, 1000 * 3 ) )

        } )

    }

    render() {

        const logoImageUrl            = '/assets/logo.png'
        const logoText                = 'Metrics'
        const mainNavigationMenuItems = []
        const pageRoutes              = []

        // TODO Events page

        mainNavigationMenuItems.push( <MenuItem url='/' key='dashboard'><><Icon fw icon='dashboard' /><span className='nav-link-text'>Dashboard</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/events' key='events'><><Icon fw icon='calendar-days' /><span className='nav-link-text'>Events</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/exercises' key='events'><><Icon fw icon='stopwatch-20' /><span className='nav-link-text'>Exercises</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/athletes' key='athletes'><><Icon fw icon='user-friends' /><span className='nav-link-text'>Athletes</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/reporting' key='reporting'><><Icon fw icon='chart-line' /><span className='nav-link-text'>Reporting</span></></MenuItem> )
        mainNavigationMenuItems.push( <MenuItem url='/import' key='import'><><Icon fw icon='file-import' /><span className='nav-link-text'>Import</span></></MenuItem> )

        pageRoutes.push( <Route path='/athlete/:id' key='athlete' children={ <AthleteNewOrEdit /> } /> )
        pageRoutes.push( <Route path='/athletes' key='athletes' children={ <Athletes /> } /> )
        pageRoutes.push( <Route path='/exercise/:id' key='exercise' children={ <ExerciseNewOrEdit /> } /> )
        pageRoutes.push( <Route path='/exercises' key='exercises' children={ <Exercises /> } /> )
        pageRoutes.push( <Route path='/event/:id' key='event' children={ <EventNewOrEdit /> } /> )
        pageRoutes.push( <Route path='/events' key='events' children={ <Events /> } /> )
        pageRoutes.push( <Route path='/import' key='import' children={ <Import /> } /> )
        pageRoutes.push( <Route path='/' key='dashboard' children={ <Dashboard /> } /> )

        const baseDataUrls = {

            'dev':                         'https://backend-afboemetricsdev.morawa.digital/',
            'afboemetrics.morawa.digital': 'https://backend-afboemetrics.morawa.digital/',

        }

        let baseDataUrl

        if ( process.env.REACT_APP_BASE_DATA_URL ) {

            baseDataUrl = process.env.REACT_APP_BASE_DATA_URL

            if ( process.env.REACT_APP_BASE_DATA_PORT ) {

                baseDataUrl += ':' + process.env.REACT_APP_BASE_DATA_PORT + '/'

            }

        } else if ( document.domain in baseDataUrls ) {

            baseDataUrl = baseDataUrls[ document.domain ]

        } else {

            baseDataUrl = baseDataUrls.dev

        }

        const conf = {

            footerLeft:    createDate().getFullYear() + ' © Morawa Digital',
            footerRight:   'AFBÖ Metrics v' + packageJson.version,
            rootComponent: <Login logoImageUrl={ logoImageUrl } logoText={ logoText } />,

            baseDataUrl,
            logoImageUrl,
            logoText,
            mainNavigationMenuItems,
            pageRoutes,

        }

        return (

            <ErrorBoundary>

                <Skynet { ...conf } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast show={ this.state.appUpdateAvailable } onClose={ () => this.hideAppUpdateNotification() }>

                        <Toast.Header>

                            <div className='flex-grow-1'>Update verfügbar</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>Eine neue App-Version ist verfügbar!</p>

                            <Button onClick={ () => this.updateApp() } disabled={ this.state.updatingApp }>{ this.state.updatingApp ? 'Installiere...' : 'Jetzt installieren' }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ErrorBoundary>

        )

    }

}

export default App