import {translate as _} from '@morawadigital/skynet-framework'
import {
  FIELD_TYPE_AUTOCOMPLETE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_TEXTFIELD
} from '../../../util/constants'

const fields = [
  {
    object: 'Athletes',
    property: 'Firstname',
    label: _('Firstname'),
    isRequired: true,
    __type: FIELD_TYPE_TEXTFIELD
  },
  {
    object: 'Athletes',
    property: 'Lastname',
    label: _('Lastname'),
    isRequired: true,
    __type: FIELD_TYPE_TEXTFIELD
  },
  {
    object: 'Athletes',
    property: 'Birthday',
    label: _('Birthday'),
    isRequired: true,
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Athletes',
    property: 'Gender',
    label: _('Gender'),
    isRequired: true,
    __type: FIELD_TYPE_AUTOCOMPLETE,
    __optionsKey: 'genders',
  },
  {
    object: 'Athletes',
    property: 'Position',
    label: _('Position'),
    isRequired: true,
    __type: FIELD_TYPE_AUTOCOMPLETE,
    __optionsKey: 'positions',
  }
]



export const getFields = () => fields

